<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card :title="'Detail Bangsal'">
          <template v-slot:body>
            <div class="row align-items-center">

              <!-- Profile Image -->
              <div class="col-md-4">
                <div class="d-flex justify-content-center align-items-center image-container">
                  <div style='height: 350px; width: 350px; position: relative'>
                    <img
                      class="image"
                      src="/images/default-profile.svg"
                      
                    >
                  </div>
                </div>
              </div>

              <!-- Table Data -->
              <div class="col">
                <table class="table mb-3">
                  <tr>
                    <td><strong>Nama</strong></td>
                    <td>{{ data.name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Kategori Bangsal</strong></td>
                    <td>{{ data.bed_category_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Deskripsi</strong></td>
                    <td>{{ data.description }}</td>
                  </tr>
                </table>
                <button
                  @click="$router.push({path: '/beds/edit/1'})"
                  class="btn mx-1 btn-success"
                  v-if="manipulateBtn == true"
                ><i class="far fa-fw fa-edit"></i> Edit</button>
                <button class="btn mx-1 btn-danger" v-if="manipulateBtn == true"><i class="far fa-fw fa-trash-alt"></i> Hapus</button>
              </div>

            </div>

            <div class="row"
              v-if="dataLoaded == true"
            >
              <div class="col-12 mt-2">
                <h6>Riwayat Penggunaan Bangsal</h6>
              </div>
              <div class="col-12 mt-2">
                <Table :bedData="data.id"></Table>
              </div>
            </div>
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import module from '@/core/modules/CrudModule.js'
import Table from '@/component/beds/Table.vue'
// import { set } from 'vue/types/umd'

export default {

  components: {
    Card,
    Table
  },

  data() {
    return {
      dataLoaded : false,
      data: {
        name:"",
        description:"",
        bed_category_id:"",
        bed_category_name:"",
      },
      manipulateBtn:false,
    }
  },

  methods:{
    async get(){
      this.data = await module.get('beds/'+ this.$route.params.id)
       if (this.data == null) {
        // Redirect To List
        this.$router.push("/masters/beds/list");
      }else{
        this.dataLoaded = true
      }
    },

    // access management
    async setActiveMenu(){

     let access_right_user = window.localStorage.getItem("access_right_display")
     let access_right = JSON.parse(access_right_user)
    
    let a
    for(a = 0; a < access_right.length; a++){
      console.log("looping")
      
      if(access_right[a] == "1302"){
        this.manipulateBtn = true
      }
      
    }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Bangsal", route: "" },
      { title: "Daftar Bangsal", route: "/beds/list" },
      { title: "Detail" },
    ])
    this.get()
    this.setActiveMenu()
  },

}
</script>

<style>
/* Dynamic Size Image */
.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>